import React, { useEffect, useState } from "react"
import { Button, Offcanvas, Form, Modal } from "react-bootstrap"
import { Link } from "gatsby"
import InfoScrollbar from "../UserInfoScroll"
import { createStaffToPay, getWindowsLocation,getDeviceCode } from "../../api/app.ts"

const TryFree = props => {
  const [show, setShow] = useState(false)

  const { setShowScroll, priceIds, cbUrl } = props

  const handleClose = () => {
    setShowScroll(true)
    setShow(false)
  }
  const handleShow = () => {
    setShowScroll(false)
    setShow(true)
  }

  const [validated, setValidated] = useState(false)

  const [emailSub, setEmailSub] = useState(true)

  const [email, setEmail] = useState("")

  const [priceId, setPriceId] = useState(priceIds.annually)

  const [showPop, setShowPop] = useState(false)
  const handlePopClose = () => setShowPop(false)

  const [popText, setPopText] = useState("")
  const [errPop, setErrPop] = useState(false)

  const handleSubmit = async event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (!form.checkValidity()) {
      setValidated(true)
      return
    }
    const params = {
      path: getWindowsLocation(),
      emailSub,
      email,
      priceId,
      origin: getDeviceCode(),
      autoEmail:true,
      ...cbUrl,
    }
    const { data, success } = await createStaffToPay(params)
    if (success) {
      const { popup, url } = data
      url && window.open(data.url, "_self")
      if (popup) {
        setPopText(popup)
        setShowPop(true)
      }
    } else {
      setErrPop(true)
    }
  }

  const toCubo = () => {
    window.open("https://cubo.to", "_self")
  }

  const tabs = [
    { name: "Monthly", priceId: priceIds.monthly, price: 15 },
    { name: "🔥Annually", priceId: priceIds.annually, price: 12 },
  ]

  return (
    <>
      <section className="tryFree" id="tryFree">
        <Button
          type="button"
          variant="primary"
          onClick={handleShow}
          className="btn"
          id="twoTryOuterBtn"
        >
          Try Now
          <img
            className="arrow"
            src="/assets/images/arrow.png"
            alt="try free"
          />
        </Button>
      </section>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        className="tryFree-offcanvas  tryFree-offcanvas2"
      >
        <InfoScrollbar extraClass={"tryFree"} />

        <div className="tryFreeTabs">
          {tabs.map((item, i) => {
            return (
              <div
                className={`tryFreeTabs_item ${
                  priceId === item.priceId ? "active" : ""
                } item-${i}`}
                key={item.priceId}
                onClick={e => setPriceId(item.priceId)}
              >
                {item.name}
              </div>
            )
          })}

          <div className="tag">
            Save<span>20</span>%
          </div>
        </div>

        <div className="texteee">Start a new way of doing business.</div>

        <div className="pirce">
          $<span>{priceId === priceIds.annually ? 12 : 15}</span>/month
        </div>

        <div className="list">
          <div className="list_item">
            <img src="/assets/icons/icon1.png" />
            Instant Activation
          </div>
          <div className="list_item">
            <img src="/assets/icons/icon2.png" />
            14-day money back guarantee
          </div>
          <div className="list_item">
            <img src="/assets/icons/icon3.png" />
            Chat & Email Support
          </div>
        </div>

        <div className="tryFreeForm">
          <Form noValidate onSubmit={handleSubmit} validated={validated}>
            <Form.Group className="tryFreeForm-input">
              <Form.Control
                required
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                id="twoEmailInput"
              />
              <Form.Control.Feedback type="invalid">
                Enter a valid email address
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" id="twoTrySubmit">
              Try Now
              <img
                className="arrow"
                src="/assets/images/arrow.png"
                alt="try free"
              />
            </Button>
          </Form>
        </div>

        <Form.Check
          className="tryFreeTips"
          type="checkbox"
          label="Get Daily Online Business Tips for Free."
          defaultChecked
          onChange={e => setEmailSub(e.target.checked)}
        />

        <div className="tryFreeFooter">
          By clicking Try Now, you agree to the{" "}
          <span>
            <Link to="/termsofservice">&nbsp;Terms of Service&nbsp;</Link>
          </span>
          and
          <span>
            <Link to="/privacypolicy">&nbsp;Privacy Policy&nbsp;</Link>
          </span>
        </div>
      </Offcanvas>

      <Modal
        show={showPop}
        onHide={handlePopClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <img src="/assets/icons/logo.png" className="poplogo" />
        </Modal.Header>
        <Modal.Body>{popText}</Modal.Body>
        <Modal.Footer className="popFooter">
          <Button variant="primary" className="popBtn" onClick={toCubo}>
            confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TryFree
